import React from "react";
import { FaFacebook } from "react-icons/fa";
import "./Footer.css";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer__content">
        <ul className="footer__socials-list">
          <li className="social-list__item">
            <a
              href="https://www.facebook.com/Jobs-Agency-103443464404268"
              className="social-list__link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook className="social-list__item-icon" />
              Facebook
            </a>
          </li>
          {/* <li className="social-list__item">
            <a href="#" className="social-list__link" target="_blank" rel="noopener noreferrer">
              <FaLinkedin className="social-list__item-icon" />
              Linkedin
            </a>
          </li> */}
        </ul>
      </div>
    </footer>
  );
}
