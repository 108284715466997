import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { BiRightArrowAlt } from "react-icons/bi";
import "./CtaNavLink.css";

export default function CtaNavLink({ className, to, children }) {
  return (
    <Link className={classNames("cta-nav-link", className)} to={to}>
      <span className="cta-nav-link__label">{children}</span>
      <BiRightArrowAlt className="cta-nav-link__icon" />
    </Link>
  );
}

CtaNavLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};
