import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { HiMenu } from "react-icons/hi";

import { HOME_ROUTE, ABOUT_ROUTE, JOBSLIST_ROUTE, FAQ_ROUTE, CONTACTS_ROUTE } from "constants/Routing";
import { LanguageSelect } from "components";

import "./Header.css";

export default function Header() {
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);

  return (
    <header className="header">
      <div className="header__inner">
        <NavLink className="header__logo" activeClassName="navigation__link--active" to={HOME_ROUTE} exact>
          <img className="logo__image" src="/assets/images/logo.png" alt="Jobs Agency Logo" title="Jobs Agency" />
        </NavLink>
        <button className="mobile-navigation-control__open-button" onClick={() => setIsOpenMobileMenu(true)}>
          <HiMenu />
        </button>
        <div
          className={classnames("header__content", {
            "header__content--open": isOpenMobileMenu,
          })}
        >
          <div className="header__mobile-navigation-control">
            <button className="mobile-navigation-control__close-button" onClick={() => setIsOpenMobileMenu(false)}>
              x
            </button>
          </div>
          <nav className="header__navigation">
            <NavLink
              className="navigation__link"
              onClick={() => setIsOpenMobileMenu(false)}
              activeClassName="navigation__link--active"
              to={HOME_ROUTE}
              exact
            >
              <FormattedMessage id="header.nav_home" />
            </NavLink>
            <NavLink
              className="navigation__link"
              onClick={() => setIsOpenMobileMenu(false)}
              activeClassName="navigation__link--active"
              to={ABOUT_ROUTE}
              exact
            >
              <FormattedMessage id="header.nav_about" />
            </NavLink>
            <NavLink
              className="navigation__link"
              onClick={() => setIsOpenMobileMenu(false)}
              activeClassName="navigation__link--active"
              to={JOBSLIST_ROUTE}
              exact
            >
              <FormattedMessage id="header.nav_joblist" />
            </NavLink>
            <NavLink
              className="navigation__link"
              onClick={() => setIsOpenMobileMenu(false)}
              activeClassName="navigation__link--active"
              to={FAQ_ROUTE}
              exact
            >
              <FormattedMessage id="header.nav_faq" />
            </NavLink>
            <NavLink
              className="navigation__link"
              onClick={() => setIsOpenMobileMenu(false)}
              activeClassName="navigation__link--active"
              to={CONTACTS_ROUTE}
              exact
            >
              <FormattedMessage id="header.nav_contacts" />
            </NavLink>
          </nav>
          <div className="header__info">
            <LanguageSelect />
          </div>
        </div>
        <div className="header__contacts">
          <a className="header__tel" href="tel: +37322844550">
            +373 69162204
          </a>
        </div>
      </div>
    </header>
  );
}
