import React, { useState, Fragment } from "react";
import { useLocation, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { IntlProvider } from "react-intl";

import { LOCALES } from "./locales";
import messages from "./messages";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const Context = React.createContext();

export default function Provider({ children, ...rest }) {
  let query = useQuery();
  const history = useHistory();
  const userLocale = query.get("lang");
  const defaultLocale = userLocale ? userLocale : LOCALES.RUSSIAN;
  const [locale, setLocale] = useState(defaultLocale);

  function selectLang(e) {
    const newLocale = e.target.value;
    setLocale(newLocale);
    localStorage.userSelectedLocale = newLocale;

    history.push({
      search: `?lang=${newLocale}`,
    });
  }

  return (
    <Context.Provider value={{ locale, selectLang }}>
      <IntlProvider locale={locale} textComponent={Fragment} messages={messages[locale]} {...rest}>
        {children}
      </IntlProvider>
    </Context.Provider>
  );
}

Provider.propTypes = {
  children: PropTypes.node,
};
