import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Grid from "@mui/material/Grid";

import { JobDetails, JobsList } from "components";
import { ContentBlock, PageTitleSlot } from "ui-kit";
import { jobs } from "constants/JobsIds";

import order_collector from "static/order_collector.jpg";
import empty_container_sorter from "static/empty_container_sorter.jpg";
import truck_driver from "static/truck_driver.jpg";
import project_manager from "static/project_manager.jpg";
import warehouse_order_collector from "static/warehouse_order_collector.jpg";
import loader_driver from "static/loader_driver.jpg";
import high_rise_loader_driver from "static/high_rise_loader_driver.jpg";
import warehouse_workers from "static/warehouse_workers.jpg";
import project_coordinators from "static/project_coordinators.jpg";
import furniture_movers from "static/furniture_movers.jpg";
import foremen from "static/foremen.jpg";
import medical_assistant from "static/medical_assistant.jpg";

const jobsList = [
  {
    job_id: jobs.order_collector.id,
    job_title: `joblist_page.job.${jobs.order_collector.id}.title`,
    job_desc: `joblist_page.job.${jobs.order_collector.id}.desc`,
    job_cover: order_collector,
  },
  {
    job_id: jobs.empty_container_sorter.id,
    job_title: `joblist_page.job.${jobs.empty_container_sorter.id}.title`,
    job_desc: `joblist_page.job.${jobs.empty_container_sorter.id}.desc`,
    job_cover: empty_container_sorter,
  },
  {
    job_id: jobs.truck_driver.id,
    job_title: `joblist_page.job.${jobs.truck_driver.id}.title`,
    job_desc: `joblist_page.job.${jobs.truck_driver.id}.desc`,
    job_cover: truck_driver,
  },
  {
    job_id: jobs.project_manager.id,
    job_title: `joblist_page.job.${jobs.project_manager.id}.title`,
    job_desc: `joblist_page.job.${jobs.project_manager.id}.desc`,
    job_cover: project_manager,
  },
  {
    job_id: jobs.warehouse_order_collector.id,
    job_title: `joblist_page.job.${jobs.warehouse_order_collector.id}.title`,
    job_desc: `joblist_page.job.${jobs.warehouse_order_collector.id}.desc`,
    job_cover: warehouse_order_collector,
  },
  {
    job_id: jobs.loader_driver.id,
    job_title: `joblist_page.job.${jobs.loader_driver.id}.title`,
    job_desc: `joblist_page.job.${jobs.loader_driver.id}.desc`,
    job_cover: loader_driver,
  },
  {
    job_id: jobs.high_rise_loader_driver.id,
    job_title: `joblist_page.job.${jobs.loader_driver.id}.title`,
    job_desc: `joblist_page.job.${jobs.loader_driver.id}.desc`,
    job_cover: high_rise_loader_driver,
  },
  {
    job_id: jobs.warehouse_workers.id,
    job_title: `joblist_page.job.${jobs.warehouse_workers.id}.title`,
    job_desc: `joblist_page.job.${jobs.warehouse_workers.id}.desc`,
    job_cover: warehouse_workers,
  },
  {
    job_id: jobs.project_coordinators.id,
    job_title: `joblist_page.job.${jobs.project_coordinators.id}.title`,
    job_desc: `joblist_page.job.${jobs.project_coordinators.id}.desc`,
    job_cover: project_coordinators,
  },
  {
    job_id: jobs.furniture_movers.id,
    job_title: `joblist_page.job.${jobs.furniture_movers.id}.title`,
    job_desc: `joblist_page.job.${jobs.furniture_movers.id}.desc`,
    job_cover: furniture_movers,
  },
  {
    job_id: jobs.foremen.id,
    job_title: `joblist_page.job.${jobs.foremen.id}.title`,
    job_desc: `joblist_page.job.${jobs.foremen.id}.desc`,
    job_cover: foremen,
  },
  {
    job_id: jobs.medical_assistant.id,
    job_title: `joblist_page.job.${jobs.medical_assistant.id}.title`,
    job_desc: `joblist_page.job.${jobs.medical_assistant.id}.desc`,
    job_cover: medical_assistant,
  },
];

export default function JobsListPage({ isTitleHide }) {
  let { path } = useRouteMatch();

  return (
    <div className="joblist-page route-page">
      {!isTitleHide && (
        <PageTitleSlot>
          <h3 className="route-page__title">
            <FormattedMessage id="joblist_page.title" />
          </h3>
        </PageTitleSlot>
      )}
      <ContentBlock>
        <Switch>
          <Route exact path={path}>
            <Grid container spacing={2}>
              <JobsList jobsList={jobsList} />
            </Grid>
          </Route>
          <Route path={`${path}/:jobId`}>
            <JobDetails />
          </Route>
        </Switch>
      </ContentBlock>
    </div>
  );
}

JobsListPage.proptype = {
  isTitleHide: PropTypes.bool,
};

JobsListPage.defaultProps = {
  isTitleHide: false,
};
