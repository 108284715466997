import React from "react";
import { FormattedMessage } from "react-intl";

import { JOBSLIST_ROUTE } from "constants/Routing";
import { Contact } from "components";
import { ContentImageBlock, ContentBlock, CtaNavLink } from "ui-kit";
import JobListPage from "../JobsListPage/JobsListPage";
import contentImage from "static/content_image_1.jpg";
import "./HomePage.css";

export default function HomePage() {
  return (
    <div className="home-page">
      <ContentBlock>
        <h1 className="home-page__title heading--xxl">
          <FormattedMessage id="home_page.title" />
        </h1>
        <article>
          <h3>
            <FormattedMessage id="home_page.vacantions_list_title" />
          </h3>
          <ul className="home-page__job-list">
            <li>
              <FormattedMessage id="home_page.vacantions_job_1" />
            </li>
            <li>
              <FormattedMessage id="home_page.vacantions_job_2" />
            </li>
            <li>
              <FormattedMessage id="home_page.vacantions_job_3" />
            </li>
          </ul>
          <CtaNavLink to={JOBSLIST_ROUTE}>
            <FormattedMessage id="home_page.all_jobs_button" />
          </CtaNavLink>
        </article>
      </ContentBlock>

      <ContentImageBlock src={contentImage} />

      <ContentBlock>
        <h3>
          <FormattedMessage id="home_page.benefits_list_title" />
        </h3>
        <ol className="home-page__benefit-list">
          <li>
            <article>
              <h4>
                <FormattedMessage id="home_page.benefit_1" />
              </h4>
              <p>
                <FormattedMessage id="home_page.benefit_desc_1" />
              </p>
            </article>
          </li>
          <li>
            <article>
              <h4>
                <FormattedMessage id="home_page.benefit_2" />
              </h4>
              <p>
                <FormattedMessage id="home_page.benefit_desc_2" />
              </p>
            </article>
          </li>
          <li>
            <article>
              <h4>
                <FormattedMessage id="home_page.benefit_3" />
              </h4>
              <p>
                <FormattedMessage id="home_page.benefit_desc_3" />
              </p>
            </article>
          </li>
          <li>
            <article>
              <h4>
                <FormattedMessage id="home_page.benefit_4" />
              </h4>
              <p>
                <FormattedMessage id="home_page.benefit_desc_4" />
              </p>
            </article>
          </li>
        </ol>
      </ContentBlock>
      <ContentBlock>
        <JobListPage></JobListPage>
      </ContentBlock>
      <ContentBlock>
        <h3>
          <FormattedMessage id="home_page.contacts_title" />
        </h3>
        <Contact />
      </ContentBlock>
    </div>
  );
}
