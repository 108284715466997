import React from "react";
import { FormattedMessage } from "react-intl";

import { ContentBlock } from "ui-kit";

import "./FormSuccess.css";

export default function FormSuccess() {
  return (
    <div className="form-success-page route-page">
      <ContentBlock>
        <h3 className="form-success-page__title">
          <FormattedMessage id="global_msg.thank_you" />
        </h3>
      </ContentBlock>
    </div>
  );
}
