import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./ContentBlock.css";

export default function ContentBlock({ className, children }) {
  return (
    <div className={classNames("content-block", className)}>{children}</div>
  );
}

ContentBlock.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
