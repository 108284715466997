import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

import { FormattedMessage } from "react-intl";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";

import { CITIZENSHIP_LIST } from "constants/CitizenshipList";
import { PAGECLIP_FORM_ACTION_URL } from "constants/Pageclip";
import { jobs } from "constants/JobsIds";

import { FORM_SUCCESS_ROUTE } from "constants/Routing";

function ContactForm() {
  const intl = useIntl();
  const [inputs, setInputs] = useState({});
  const [citizenship, setCitizenship] = useState([]);
  const formRef = useRef();
  const history = useHistory();

  const handleInputChange = (e) => {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCitizenshipChange = (event) => {
    const {
      target: { value },
    } = event;
    setCitizenship(value);
  };

  useEffect(() => {
    window.Pageclip.form(formRef.current, {
      onResponse: function (error, response) {
        if (response.data === "ok") {
          setInputs({});
          setCitizenship([]);
          history.push(FORM_SUCCESS_ROUTE);
        }

        if (response.data !== "ok") {
          throw new Error("Request Failed");
        }
      },
    });
  });

  return (
    <div className="contact-form">
      <form className="pageclip-form" action={PAGECLIP_FORM_ACTION_URL} method="post" ref={formRef}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label={<FormattedMessage id={`contact_form.first_name_input.title`} />}
              variant="outlined"
              fullWidth
              onChange={handleInputChange}
              name="firstName"
              value={inputs.firstName || ""}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={<FormattedMessage id={`contact_form.last_name_input.title`} />}
              variant="outlined"
              fullWidth
              onChange={handleInputChange}
              name="lastName"
              value={inputs.lastName || ""}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={<FormattedMessage id={`contact_form.tel_input.title`} />}
              variant="outlined"
              fullWidth
              onChange={handleInputChange}
              name="tel"
              value={inputs.tel || ""}
              required
              type="tel"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="citizenship-label">
                <FormattedMessage id="contact_form.citizenship_input.title" /> *
              </InputLabel>
              <Select
                labelId="citizenship-label"
                multiple
                value={citizenship}
                onChange={handleCitizenshipChange}
                required
                input={
                  <OutlinedInput
                    name="citizenship"
                    label={<FormattedMessage id="contact_form.citizenship_input.title" />}
                  />
                }
                renderValue={(selected) => selected.join(", ")}
                fullWidth
              >
                {CITIZENSHIP_LIST.map((citizenshipItem) => (
                  <MenuItem key={citizenshipItem} value={citizenshipItem}>
                    <Checkbox checked={citizenship.indexOf(citizenshipItem) > -1} />
                    <ListItemText primary={citizenshipItem} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="job-label">
                <FormattedMessage id="contact_form.job_input.title" /> *
              </InputLabel>
              <Select
                labelId="job-label"
                id="job-select"
                name="job"
                value={inputs.job || ""}
                label="Age"
                onChange={handleInputChange}
                required
              >
                {Object.keys(jobs).map((jobItem) => {
                  const jobItemText = intl.formatMessage({
                    id: `joblist_page.job.${jobItem}.title`,
                  });
                  return (
                    <MenuItem key={jobItem} value={jobItemText}>
                      {jobItemText}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <FormattedMessage id={`contact_form.inputs_legend_required`} />
            </Typography>

            <br />

            <Button className="pageclip-form__submit" variant="contained" size="large" type="submit" fullWidth>
              <FormattedMessage id="contact_form.submit_button.label" />
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default ContactForm;
