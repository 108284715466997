import React from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Grid from "@mui/material/Grid";

import { ContactForm } from "components";
import { FaqPage, JobsListPage } from "../../pages/";

import "./JobDetails.css";

export default function JobDetails() {
  let { jobId } = useParams();
  return (
    <div className="job-details">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3>
            <FormattedMessage id={`joblist_page.job.${jobId}.title`} />
          </h3>
        </Grid>
        <Grid item xs={12} md={6}>
          <article>
            <h5>
              <FormattedMessage id={`jobdetails.job_type_title`} />
            </h5>
            <p>
              <FormattedMessage id={`jobdetails.job_type.${jobId}.desc`} values={{ linebreak: <br /> }} />
            </p>
          </article>
          <article>
            <h5>
              <FormattedMessage id={`jobdetails.job_benefits_title`} />
            </h5>
            <p>
              <FormattedMessage
                id={`jobdetails.job_benefits.${jobId}.desc`}
                values={{
                  b: (...chunks) => <b>{chunks}</b>,
                  linebreak: <br />,
                }}
              />
            </p>
          </article>
          <article>
            <h5>
              <FormattedMessage id={`jobdetails.job_requires_title`} />
            </h5>
            <p>
              <FormattedMessage id={`jobdetails.job_requires.${jobId}.desc`} values={{ linebreak: <br /> }} />
            </p>
          </article>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="job-details__contact">
            <h5>
              <FormattedMessage id="jobdetails.job_contact_title" />
            </h5>
            <ContactForm />
          </div>
        </Grid>
        <Grid item xs={12}>
          <section className="job-details__section">
            <JobsListPage isTitleHide></JobsListPage>
          </section>
        </Grid>
        <Grid item xs={12}>
          <section>
            <FaqPage isMinimalHeader></FaqPage>
          </section>
        </Grid>
      </Grid>
    </div>
  );
}
