import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import "./ActionButton.css";

export default function ActionButton({ children, to }) {
  return (
    <Link className="job-item-card__action-button" to={to}>
      {children}
    </Link>
  );
}

ActionButton.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
};

ActionButton.defaultProps = {
  to: "/",
};
