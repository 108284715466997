/**
 * Returns a curried equivalent of the provided function. The curried function
 * has an unusual capability in that not all of its arguments have to be provided
 * at the same time. If `f` is a function with arity 3 and `g` is `curry(f)`, the
 * following are equivalent:
 *
 *   - `g(1)(2)(3)`
 *   - `g(1)(2, 3)`
 *   - `g(1, 2)(3)`
 *   - `g(1, 2, 3)`
 *
 * @param {Function} fn The function to curry.
 * @param {...*} preAppliedArgs Any number of arguments to pre-apply
 * @return {Function} A new, curried function.
 * @example
 *
 *      const addFourNumbers = (a, b, c, d) => a + b + c + d;
 *      const curriedAddFourNumbers = curry(addFourNumbers);
 *      const f = curriedAddFourNumbers(1, 2);
 *      const g = f(3);
 *      g(4); //=> 10
 */
const curry = (fn, ...preAppliedArgs) => (...args) => {
  const allArgs = [...preAppliedArgs, ...args];

  return allArgs.length < fn.length ? curry(fn, ...allArgs) : fn(...allArgs);
};

export default curry;
