import { LOCALES } from "../locales";

// eslint-disable-next-line
export default {
  [LOCALES.ROMANIAN]: {
    "global_msg.thank_you": "Vă mulțumim pentru cerere, vă vom contacta în scurt timp.",
    "header.nav_home": "Start",
    "header.nav_about": "Despre noi",
    "header.nav_joblist": "Oferte de lucru",
    "header.nav_faq": "Întrebări frecvente",
    "header.nav_contacts": "Contacte",

    "home_page.title": "Lucru in Germania",
    "home_page.vacantions_list_title": "LA MOMENT ESTE NECESARĂ",
    "home_page.vacantions_job_1": "COLECTORI DE COMENZI",
    "home_page.vacantions_job_2": "ÎNCĂRCĂTORI ÎN DEPOZITUL BĂUTURILOR",
    "home_page.vacantions_job_3": "Brigadieri",
    "home_page.all_jobs_button": "TOATE ANUNȚURILE",
    "home_page.benefits_list_title": "Avantajele colaborării cu noi",
    "home_page.benefit_1": "CALITATE",
    "home_page.benefit_desc_1":
      "Ne concentrăm pe standardele muncii care asigură un management eficient al calității. Ne străduim să îndeplinim așteptările clienților și angajaților noștri.",
    "home_page.benefit_2": "ONESTITATE",
    "home_page.benefit_desc_2":
      "Considerăm că este o datorie să respectăm standardele etice. Afacerea noastră se bazează pe onestitate și respectarea legilor aplicabile.",
    "home_page.benefit_3": "LOIALITATE",
    "home_page.benefit_desc_3": "Apreciem relațiile loiale cu angajații noștri, clienții și partenerii străini.",
    "home_page.benefit_4": "RESPONSABILITATE",
    "home_page.benefit_desc_4": "Înțelegem responsabilitatea noastră socială și susținem valorile noastre de bază.",
    "home_page.contacts_title": "Contacte",

    "about_page.title": "Despre noi",
    "about_page.subtitle": "Misiunea Jobs Agency",
    "about_page.desc":
      "- Este o companie de prestare a unui spectru larg de servicii în domeniul resurselor umane. {linebreak} - Este o companie de recrutare si plasare a forţrei de muncă în străinîtate, care dispune de o varietate de oferte de muncă peste hotare",

    "about_page.subtitle_1": "De ce noi?",
    "about_page.desc_1":
      "- Jobs Agency este companie licențiată în activitatea de resurse umnae şi de plasarea în câmpul muncii peste hotare{linebreak} - Este  o companie autorizată la Ministerul Sănătăţii, Muncii şi Protecţiei Sociale, contractele noastre sunt înregistrate la ANOFM{linebreak} - Are o echipă experimentată în domeniul resurse umane, care vă asigură asistenţă  de calitate pe toată durata contractului {linebreak} - Colaborează direct cu angajatori  străini siguri fără comisioane de intermediere",

    "about_page.benefits_title": "Prestăm servicii în domeniul resurse umane",
    "about_page.benefits_desc":
      "- Outsourcing de resurse umane complex/parţial{linebreak} - Consultanţa în domeniul resurse umane: {linebreak} - Crearea subdiviziunii de resurse umane{linebreak} - Elaborarea actelor normative privind gestiunbea resurselor umane la nivel de unitate{linebreak} - Administrarea personalului {linebreak} - Respectarea legislaţiei muncii{linebreak} - Recrutarea personalului {linebreak} - Onboarding-ul{linebreak} - Motivarea, remunerarea şi evaluarea performanţei angajaţilor{linebreak} - Normarea muncii{linebreak} - Elaborarea fişelor de post{linebreak} - Instruirea peresonalului{linebreak} - Planurile de continuitate şi rezerva de personal{linebreak} - Securitatea şi sănătatea în muncă{linebreak} - Auditul resurselor umane{linebreak} - Dezvoltarea culturii corporative{linebreak} - Dezvoltarea brandului de angajator",

    "about_page.benefits_title_1": "Prestăm servicii de recrutare si plasare a forţrei de muncă în străinătate",
    "about_page.benefits_desc_1":
      "Partenerii noştri din Germania:{linebreak}{linebreak}HAM-LOG-GRUPPE Holding GmbH, care este o companie ce furnizează servicii de logistică în Germania și în alte țări europene în conformitate cu cele mai înalte standarde și valori. HAM-LOG-GRUPPE Holding GmbH are peste 60 de proiecte în toată Germania, de la Hamburg la München.  Cu angajații sunt încheiate contractele de muncă germane, care oferă toate tipurile de garanții sociale în Germania, precum și posibilitatea de a primi o pensie și îndemnizaţii pentru copii. Compania oferă  angajaţilor toate oportunitățile de creştere în carieră: mulți au devenit conducători începîbd cariera în companie  de la treapta de jos.{linebreak}{linebreak}Medical Park",

    "joblist_page.title": "OFERTE DE LUCRU",
    "joblist_page.action_button": "Doresc să lucrez",

    // JOB LIST OFFERS
    // Order Collector
    "joblist_page.job.order_collector.title": "Selector de comenzi depozit de băuturi",
    "joblist_page.job.order_collector.desc":
      "Colectarea și ambalarea mărfurilor utilizând un sistem de rezervare vocală. Lucrul cu un cărucior electric.<b>1600 euro</b>",

    // Empty Container Sorter
    "joblist_page.job.empty_container_sorter.title": "Sortator recipiente (sticle și cutii) goale",
    "joblist_page.job.empty_container_sorter.desc":
      "Recipientele goale (sticle și cutii) sunt sortate și asamblate cu precizie conform standardului.",

    // Truck Driver
    "joblist_page.job.truck_driver.title": "Șoferi de camion",
    "joblist_page.job.truck_driver.desc":
      "Conducerea camioanelor, transportul la timp al mărfurilor din depozitele logistice către supermarket.",

    // Project manager
    "joblist_page.job.project_manager.title": "Manager de proiect",
    "joblist_page.job.project_manager.desc": "Conducerea și coordonarea echipei (100-150 angajați).",

    // Warehouse Order Collector
    "joblist_page.job.warehouse_order_collector.title": "Colectori de comenzi în depozitul de mărfuri",
    "joblist_page.job.warehouse_order_collector.desc":
      "Specificitatea muncii - utilizarea unui scaner pentru colectarea mărfurilor la comandă. Lucrul cu un cărucior electric. <b>1600 euro</b>",

    // Loader Driver
    "joblist_page.job.loader_driver.title": "Șofer de stivuitor",
    "joblist_page.job.loader_driver.desc":
      "Descărcarea / încărcarea camioanelor, depozitarea mărfurilor. Evidența mărfurilor primite și încălcările ambalajului acestora.",

    // High Rise Loader Driver
    "joblist_page.job.high_rise_loader_driver.title": "Șofer pe încărcătoar frontal",
    "joblist_page.job.high_rise_loader_driver.desc":
      "Descărcarea / încărcarea camioanelor, depozitarea mărfurilor (la o înălțime de până la 10 m). Remedierea bunurilor primite și încălcarea ambalajului acestora.Toți angajații noi sunt informați și familiarizați cu locul de muncă.",

    // Warehouse Workers
    "joblist_page.job.warehouse_workers.title": "Lucrători auxiliari de depozit",
    "joblist_page.job.warehouse_workers.desc":
      "Lucrătorul depozitului este responsabil pentru ordinea și curățenia depozitului pe parcursul întregii zile de lucru: colectează paleți goi, carton și folie. Lucrul cu un cărucior electric.Toți angajații noi sunt informați și familiarizați cu locul de muncă.",

    // Project Coordinators
    "joblist_page.job.project_coordinators.title": "Coordonatorii proiectului",
    "joblist_page.job.project_coordinators.desc":
      "Responsabilitatea include: coordonarea muncii și a angajaților (până la 50 de angajați); programarea muncii și a vacanțelor, distribuirea și planificarea sarcinilor, funcțiilor.",

    // Furniture Movers
    "joblist_page.job.furniture_movers.title": "Hamali depozit de mobilă",
    "joblist_page.job.furniture_movers.desc":
      "Descărcarea mărfurilor dintr-o remorcă folosind un stivuitor electric sau un cărucior electric; sortarea și scanarea mărfurilor, raportarea către conducere. <b>1500 euro</b>",

    // Foremen
    "joblist_page.job.foremen.title": "Șef de tură",
    "joblist_page.job.foremen.desc":
      "Responsabilitatea Șefului de tură include pregătirea programelor de lucru, distribuirea și planificarea lucrărilor.",

    // Medical Assistant
    "joblist_page.job.medical_assistant.title": "Asistent medical",
    "joblist_page.job.medical_assistant.desc":
      "Acordarea primului ajutor medical.Îngrijirea pacientului.Asistarea medicilor, sterilizarea instrumentelor.",

    // JOB DETAILS TITLES
    "jobdetails.job_type_title": "Tipul de muncă:",
    "jobdetails.job_benefits_title": "Compania oferă:",
    "jobdetails.job_requires_title": "Cerințe:",
    "jobdetails.job_contact_title": "Contactează-ne",

    // JOB DETAILS OFFERS
    // Order Collector
    "jobdetails.job_type.order_collector.desc":
      "Colectarea și ambalarea mărfurilor folosind scanere. Lucrul cu un cărucior electric. Verificarea bunurilor și comenzilor selectate și pregătirea pentru expediere. Toți angajații noi sunt informați și familiarizați cu locul de muncă.",
    "jobdetails.job_benefits.order_collector.desc":
      "Contractul de muncă german și toate garanțiile sociale; Program de lucru stabil, salarii stabile pe baza rezultatelor; Instruire la locul de muncă; Program flexibil de vacanță; Haine de lucru.  Returnare cheltuielilor pentru achiziția încălțămitei speciale cu protecția degetelor de la picioare (safty shoes) (40 €  se transferă în contul dvs. bancar cu primul salariu). Returnare cheltuielilor de transport din Moldova în Germania (100  € se transferă în contul dvs. bancar cu primul salariu); Cazarea gratuită cu toate facilitățile, acces la internet lângă zona de lucru. (Plata pentru serviciile comunale 150€ / lună sunt deduse din salarii); {linebreak} <b>Salariu de la 1300 euro</b> (după impozite) și în dependență de rezultat. Bonusuri pentru supraîndeplinirea planului. Plata în avans în funcție de necesități și acord.",
    "jobdetails.job_requires.order_collector.desc":
      "- Experiență de lucru (avantaj); {linebreak} - Stare foarte bună de sănătate fizică; {linebreak} - Responsabilitate, orientare rapidă; {linebreak} - Fără obiceiuri negative; {linebreak} - Mașină pentru deplasarea la / de la locul de muncă (avantaj); {linebreak} - Pentru mai multe informații despre proiect și condițiile de lucru, contactați managerii noștri la alegere.",

    // Empty Container Sorter
    "jobdetails.job_type.empty_container_sorter.desc":
      "Recipientele goale (sticle și cutii) sunt sortate și asamblate cu precizie conform standardului. Sortarea containerelor (cutii și sticle) după producător, formă și culoare. Munca în echipă, lucrează în diferite grupuri de lucru pe tot parcursul zilei. Toți angajații noi sunt informați și familiarizați cu locul de muncă",
    "jobdetails.job_benefits.empty_container_sorter.desc":
      "Contractul de muncă german și toate garanțiile sociale;{linebreak} Program de lucru stabil, salarii stabile;{linebreak} Instruire la locul de muncă;{linebreak} Program flexibil de vacanță;{linebreak} Haine de lucru. {linebreak} Returnare cheltuielilor pentru achiziția încălțămitei speciale cu protecția degetelor de la picioare (safty shoes) (40 €  se transferă în contul dvs. bancar cu primul salariu).{linebreak} Returnare cheltuielilor de transport din Moldova în Germania (100  € se transferă în contul dvs. bancar cu primul salariu);{linebreak} Cazarea gratuită cu toate facilitățile, acces la internet lângă zona de lucru.(Plata pentru serviciile comunale 150€ / lună sunt deduse din salarii);{linebreak} <b>Salariu de la 1500 euro</b> în dependență de rezultate. Bonusuri pentru supraîndeplinirea planului. Plata în avans în funcție de necesități și acord.",
    "jobdetails.job_requires.empty_container_sorter.desc":
      "- Rezistență, ritm rapid de lucru; {linebreak} - Stare bună de sănătate fizică (avantaj); {linebreak} - Fără obiceiuri rele; {linebreak} - Abilitatea de a lucra în echipă; {linebreak} - Mașină pentru deplasarea la / de la locul de muncă (avantaj); {linebreak} - Pentru mai multe informații despre proiect și condițiile de lucru, vă rugăm să contactați managerii noștri de selecție.",

    // Truck Driver
    "jobdetails.job_type.truck_driver.desc":
      "Gestionarea transportului de marfă (cu / fără remorcă). Încărcarea sau descărcarea mărfurilor la depozit, livrarea către client, descărcarea la un loc specificat, verificarea numărului de mărfuri în conformitate cu scrisoarea de parcurs. Încărcarea containerelor goale, verificarea conformității faptului cu documentele, livrarea înapoi la depozit. Remedierea oricărei discrepanțe, raportarea către conducere. Încărcarea sau descărcarea se efectuează folosind un cărucior electric.",
    "jobdetails.job_benefits.truck_driver.desc":
      "Contractul de muncă german și toate garanțiile sociale;{linebreak} Program de lucru stabil, salarii stabile;{linebreak} Instruire la locul de muncă;{linebreak} Program flexibil de vacanță;{linebreak} Haine de lucru. {linebreak} Returnare cheltuielilor pentru achiziția încălțămitei speciale cu protecția degetelor de la picioare (safty shoes) (40 €  se transferă în contul dvs. bancar cu primul salariu).{linebreak} Returnare cheltuielilor de transport din Moldova în Germania (100  € se transferă în contul dvs. bancar cu primul salariu);{linebreak} Cazarea gratuită cu toate facilitățile, acces la internet lângă zona de lucru. (Plata pentru serviciile comunale 150€ / lună sunt deduse din salarii);{linebreak} <b>Salariul de la 2000</b> de euro (după impozite), depinde de rezultatele muncii. Sunt oferite bonusuri motivante. Prin acord, este posibilă plata în avans.",
    "jobdetails.job_requires.truck_driver.desc":
      "- Este necesar un permis de conducere european pentru a opera un vehicul de marfă (cu / fără remorcă);{linebreak} - Experiență de muncă;{linebreak} - Atitudine responsabilă față de muncă;{linebreak} - Atentie si orientare rapida;{linebreak} - Bună sănătate fizică;{linebreak} - Fără obiceiuri negative;{linebreak} - Mașină pentru deplasarea la / de la locul de muncă (avantaj);",

    // Project manager
    "jobdetails.job_type.project_manager.desc":
      "- Conducerea și organizarea muncii în echipă (100-150 angajați);{linebreak}- Prezentarea companiei în proiecte cu un client;{linebreak}- Comunicarea și soluționarea problemelor actuale cu managerii și clienții;{linebreak}- Organizare de înaltă calitate și eficientă a muncii și managementului proceselor;{linebreak}- Planificarea și analiza costurilor și bugetului proiectului;{linebreak}- Planificarea muncii și nevoile lucrătorilor;{linebreak}- Asistență personalului din agențiile guvernamentale (dacă este necesar);{linebreak}- Team building, instruire, integrare de noi angajați;{linebreak}- Furnizarea de noi proiecte, vizite pe site.",
    "jobdetails.job_benefits.project_manager.desc":
      "- Contract de muncă german;{linebreak}- Muncă dinamică și responsabilă într-o companie internațională;{linebreak}- Salarii competitive (de la 2000 Eur net);{linebreak}- Locuințe în Germania (angajatorul plătește chiria);{linebreak}- Mașină",
    "jobdetails.job_requires.project_manager.desc":
      "- Cunoașterea bună (scrisă și orală) a limbii germane (cel puțin nivel B2);{linebreak}- Abilitatea organizatorică, capacitatea de a uni și motiva echipa;{linebreak}- Abilități excelente de planificare, organizare și management;{linebreak}- Profesionalism în relațiile cu angajații și clienții;{linebreak}- Reacție rapidă și clară la rezolvarea problemelor;{linebreak}- gândire analitică, logică, experiență în calculul performanței;{linebreak}- Independență și responsabilitate;{linebreak}- Onestitate și auto-motivație pentru a obține rezultate;{linebreak}- Permis de conducere european.",

    // Warehouse Order Collector
    "jobdetails.job_type.warehouse_order_collector.desc":
      "Colectarea și ambalarea mărfurilor folosind scanere. Lucrul cu un cărucior electric. Verificarea bunurilor și comenzilor selectate și pregătirea pentru expediere. Toți angajații noi sunt informați și familiarizați cu locul de muncă.",
    "jobdetails.job_benefits.warehouse_order_collector.desc":
      "Contractul de muncă german și toate garanțiile sociale;{linebreak} Program de lucru stabil, salarii stabile pe baza rezultatelor;{linebreak} Instruire la locul de muncă;{linebreak} Program flexibil de vacanță;{linebreak} Haine de lucru. {linebreak} Returnare cheltuielilor pentru achiziția încălțămitei speciale cu protecția degetelor de la picioare (safty shoes) (40 €  se transferă în contul dvs. bancar cu primul salariu).{linebreak} Returnare cheltuielilor de transport din Moldova în Germania (100  € se transferă în contul dvs. bancar cu primul salariu);{linebreak} Cazarea gratuită cu toate facilitățile, acces la internet lângă zona de lucru.{linebreak} (Plata pentru serviciile comunale 150€ / lună sunt deduse din salarii);{linebreak} <b>Salariu de la 1300 euro</b> (după impozite) și în dependență de rezultat. Bonusuri pentru supraîndeplinirea planului.{linebreak} Plata în avans în funcție de necesități și acord.",
    "jobdetails.job_requires.warehouse_order_collector.desc":
      "- Experiență de lucru (avantaj);{linebreak} - Stare foarte bună de sănătate fizică;{linebreak} - Responsabilitate, orientare rapidă;{linebreak} - Fără obiceiuri negative;{linebreak} - Mașină pentru deplasarea la / de la locul de muncă (avantaj);{linebreak} - Pentru mai multe informații despre proiect și condițiile de lucru, contactați managerii noștri la alegere.",

    // Loader Driver
    "jobdetails.job_type.loader_driver.desc":
      "Descărcarea / încărcarea camioanelor, depozitarea mărfurilor. Evidența mărfurilor primite și încălcările ambalajului acestora.Lucrul cu încărcătoare cu mai multe furci pe diesel sau electrice: 2-4-6-furci. Toți angajații noi sunt informați și familiarizați cu locul de muncă.",
    "jobdetails.job_benefits.loader_driver.desc":
      "Contractul de muncă german și toate garanțiile sociale;{linebreak}Program de lucru stabil, salarii stabile;{linebreak}Instruire la locul de muncă;{linebreak}Program flexibil de vacanță;{linebreak}Haine de lucru;{linebreak}Returnare cheltuielilor pentru achiziția încălțămitei speciale cu protecția degetelor de la picioare (safty shoes) (40 €  se transferă în contul dvs. bancar cu primul salariu).{linebreak}Returnare cheltuielilor de transport din Moldova în Germania (100  € se transferă în contul dvs. bancar cu primul salariu);{linebreak}Cazarea gratuită cu toate facilitățile, acces la internet lângă zona de lucru.(Plata pentru serviciile comunale 150€ / lună sunt deduse din salarii);{linebreak} <b>Salariu de la 1500 euro</b> (după impozite) și în dependență de rezultat. Bonusuri pentru supraîndeplinirea planului.Plata în avans în funcție de necesități și acord.",
    "jobdetails.job_requires.loader_driver.desc":
      "- Permis de conducere obligatoriu pentru a conduce un stivuitor;{linebreak} - Experiență de muncă;{linebreak} - Atentie si orientare rapida;{linebreak} - Stare bună de sănătate;{linebreak} - Fără obiceiuri negative;{linebreak} - Abilitatea de a lucra în echipă;{linebreak} - Mașină pentru deplasarea la / de la locul de muncă (avantaj);{linebreak} - Pentru mai multe informații despre proiect și condițiile de lucru, vă rugăm să contactați managerii noștri de selecție.",

    // High Rise Loader Driver
    "jobdetails.job_type.high_rise_loader_driver.desc":
      "Descărcarea / încărcarea camioanelor, depozitarea mărfurilor (la o înălțime de până la 10 m). Remedierea bunurilor primite și încălcarea ambalajului acestora.Toți angajații noi sunt informați și familiarizați cu locul de muncă.",
    "jobdetails.job_benefits.high_rise_loader_driver.desc":
      "Descărcarea / încărcarea camioanelor, depozitarea mărfurilor (la o înălțime de până la 10 m). Remedierea bunurilor primite și încălcarea ambalajului acestora.{linebreak} Toți angajații noi sunt informați și familiarizați cu locul de muncă.{linebreak} Compania oferă:{linebreak} Contractul de muncă german și toate garanțiile sociale;{linebreak} Program de lucru stabil, salarii stabile;{linebreak} Instruire la locul de muncă;{linebreak} Program flexibil de vacanță;{linebreak} Haine de lucru. {linebreak} Returnare cheltuielilor pentru achiziția încălțămitei speciale cu protecția degetelor de la picioare (safty shoes) (40 €  se transferă în contul dvs. bancar cu primul salariu). Returnare cheltuielilor de transport din Moldova în Germania (100  € se transferă în contul dvs. bancar cu primul salariu);{linebreak} Cazarea gratuită cu toate facilitățile, acces la internet lângă zona de lucru. (Plata pentru serviciile comunale 150€ / lună sunt deduse din salarii);{linebreak} <b>Salariul de la 1500</b> de euro (după impozite), și în dependență de rezultat. Sunt oferite bonusuri motivante.Prin acord, este posibilă plata în avans.",
    "jobdetails.job_requires.high_rise_loader_driver.desc":
      "- Permis de conducere obligatoriu pentru a conduce un stivuitor;{linebreak} - Experiență cu un încărcător de mare altitudine;{linebreak} - Atentie si orientare rapida;{linebreak} - Stare bună de sănătate;{linebreak} - Fără obiceiuri negative;{linebreak} - Abilitatea de a lucra în echipă;{linebreak} - Mașină pentru deplasarea la / de la locul de muncă (avantaj);{linebreak} - Pentru mai multe informații despre proiect și condițiile de lucru, vă rugăm să contactați managerii noștri de selecție.",

    // Warehouse Workers
    "jobdetails.job_type.warehouse_workers.desc":
      "Lucrătorul depozitului este responsabil pentru ordinea și curățenia depozitului pe parcursul întregii zile de lucru: colectează paleți goi, carton și folie. Lucrul cu un cărucior electric.Toți angajații noi sunt informați și familiarizați cu locul de muncă.",
    "jobdetails.job_benefits.warehouse_workers.desc":
      "Contractul de muncă german și toate garanțiile sociale;{linebreak}Program de lucru stabil, salarii stabile;{linebreak}Instruire la locul de muncă;{linebreak}Program flexibil de vacanță;{linebreak}Haine de lucru. {linebreak}Returnare cheltuielilor pentru achiziția încălțămitei speciale cu protecția degetelor de la picioare (safty shoes) (40 €  se transferă în contul dvs. bancar cu primul salariu).{linebreak}Returnare cheltuielilor de transport din Moldova în Germania (100  € se transferă în contul dvs. bancar cu primul salariu);{linebreak}Cazarea gratuită cu toate facilitățile, acces la internet lângă zona de lucru.(Plata pentru serviciile comunale 150€ / lună sunt deduse din salarii);{linebreak}<b>Salariul de la 1300</b> de euro (după impozite), și în dependență de rezultat. Sunt oferite bonusuri motivante.Prin acord, este posibilă plata în avans.",
    "jobdetails.job_requires.warehouse_workers.desc":
      "- Experiență de muncă;{linebreak} - Stare bună de sănătate;{linebreak} - Rezistență, atenție și orientare rapidă;{linebreak} - Fără obiceiuri negative;{linebreak} - Experiență de conducere a stivuitorului;{linebreak} - Mașină pentru deplasarea la / de la locul de muncă (avantaj);Pentru mai multe informații despre proiect și condițiile de lucru, vă rugăm să contactați managerii noștri de recrutare.",

    // Project Coordinators
    "jobdetails.job_type.project_coordinators.desc":
      "Responsabilitatea include: coordonarea muncii și a angajaților (până la 50 de angajați); programarea muncii și a vacanțelor, distribuirea și planificarea sarcinilor, funcțiilor; organizarea eficientă și de înaltă calitate a muncii într-un depozit logistic și gestionarea proceselor; preluarea comenzilor; planificarea muncii; creșterea productivității; gestionarea inregistrarilor; instruirea angajaților; ajutarea lucrătorilor să comunice în limba germană; întâlnirea cu noi angajați, cazare, organizarea sosirii la locul de muncă;întreținerea locuințelor; înregistrarea lucrătorilor în Germania; livrarea la o instituție medicală în caz de deteriorare a sănătății angajatului. Comunicare cu Șeful de schimb la depozit în limba germană.Toți angajații noi sunt informați și familiarizați cu locul de muncă",
    "jobdetails.job_benefits.project_coordinators.desc":
      "Muncă dinamică și responsabilă într-o companie internațională;{linebreak} Locuințe în Germania;{linebreak}  Posibilitatea de concedii periodice;{linebreak} Condiții de muncă bune;{linebreak} Oportunități de carieră pentru angajați motivați;{linebreak} Salarii competitive.{linebreak} <b>Salariul de la 1600 euro</b> (după impozite), și în dependență de rezultat.{linebreak} Cu plată suplimentară pentru rezultate (sistem bonus).",
    "jobdetails.job_requires.project_coordinators.desc":
      "- Experiență în domeniul logisticii, transporturilor;{linebreak} - Cunoașterea bună a limbii germane în scris și oral (cel puțin nivel B2);{linebreak} - Experiență de management, capacitate de a uni și motiva echipa;{linebreak} - Programarea și distribuirea sarcinilor;{linebreak} - Rezolvarea rapidă și flexibilă a problemelor;{linebreak} - Propria mașină.",

    // Furniture Movers
    "jobdetails.job_type.furniture_movers.desc":
      "Manipularea mărfurilor: descărcarea mărfurilor dintr-o remorcă folosind un stivuitor electric sau un cărucior electric; sortarea și scanarea mărfurilor (precum și primirea mărfurilor), remedierea unei încălcări a ambalajului sau deteriorarea mărfurilor, raportarea către conducere. Selectarea mărfurilor în conformitate cu comanda, completarea actelor de parcurs și depozitarea la locul de încărcare. Încărcarea mărfurilor într-un camion. Asigurarea colectării în timp util a mărfurilor.Toți angajații noi sunt informați și familiarizați cu locul de muncă.",
    "jobdetails.job_benefits.furniture_movers.desc":
      "Contractul de muncă german și toate garanțiile sociale;{linebreak}Program de lucru stabil, salarii stabile;{linebreak}Instruire la locul de muncă;{linebreak}Program flexibil de vacanță;{linebreak}Haine de lucru. {linebreak}Returnare cheltuielilor pentru achiziția încălțămitei speciale cu protecția degetelor de la picioare (safty shoes) (40 €  se transferă în contul dvs. bancar cu primul salariu).{linebreak}Returnare cheltuielilor de transport din Moldova în Germania (100  € se transferă în contul dvs. bancar cu primul salariu);{linebreak}Cazarea gratuită cu toate facilitățile, acces la internet lângă zona de lucru.(Plata pentru serviciile comunale 150€ / lună sunt deduse din salarii);{linebreak}<b>Salariul de la 1500 de euro</b> (după impozite), și în dependență de rezultat. Sunt oferite bonusuri motivante.{linebreak}Prin acord, este posibilă plata în avans.",
    "jobdetails.job_requires.furniture_movers.desc":
      "- Experiență de muncă;{linebreak} - Stare bună de sănătate;{linebreak} - Atenție și orientare rapidă;{linebreak} - Responsabilitate;{linebreak} - Fără obiceiuri negative;{linebreak} - Mașină pentru deplasarea la / de la locul de muncă (avantaj);{linebreak} - Cunoașterea limbii germane este opțională;",

    // Foremen
    "jobdetails.job_type.foremen.desc":
      "Responsabilitatea Șefului de tură include pregătirea programelor de lucru, distribuirea și planificarea lucrărilor, funcții; menținerea comenzii în depozit, rezolvarea promptă a problemelor; organizarea eficientă și de înaltă calitate a muncii într-un depozit logistic și managementul proceselor. Și, de asemenea: controlul asupra implementării sarcinilor și standardelor, asigurarea respectării cerințelor de siguranță a muncii. Comunicare cu Șeful de schimb la depozit în limba germană.Toți angajații noi sunt informați și familiarizați cu locul de muncă.",
    "jobdetails.job_benefits.foremen.desc":
      "Muncă dinamică și responsabilă într-o companie internațională;{linebreak}Locuințe în Germania;{linebreak}Posibilitatea de concedii periodice;{linebreak}Condiții bune de muncă;{linebreak}Oportunități de carieră pentru angajați;{linebreak}Salarii competitive.{linebreak}Salariu negociat;{linebreak}Cu plată suplimentară pentru rezultate (sistem bonus).",
    "jobdetails.job_requires.foremen.desc":
      "- Experiență în domeniul logisticii, transporturilor;{linebreak} - Cunoașterea bună a limbii germane în scris și oral (cel puțin nivelul B1);{linebreak} - Experiență de management, capacitate de a uni și motiva echipa;{linebreak} - Programarea și distribuirea sarcinilor;{linebreak} - Rezolvarea rapidă și flexibilă a problemelor;{linebreak} - Propria mașină.",

    // Medical Assistant
    "jobdetails.job_type.medical_assistant.desc":
      "Acordarea primului ajutor medical.Îngrijirea pacientului.Asistarea medicilor, sterilizarea instrumentelor.Colectarea de materiale biologice pentru cercetare.Executarea prescrierilor medicale.Supravegherea pacienţilor.Eliminarea deșeurilor medicale.",
    "jobdetails.job_benefits.medical_assistant.desc":
      "Contract de muncă german;{linebreak}Muncă dinamică și responsabilă într-o companie internațională;{linebreak}Salarii competitive <b>(de la 1950 Eur pînă la 2600 Eur)</b>, în dependent de calificare;",
    "jobdetails.job_requires.medical_assistant.desc":
      "- Cunoașterea bună (scrisă și orală) a limbii germane (cel puțin nivelul B1);{linebreak} - Educație medicală specializată;{linebreak} - Certificat/Diplomă de confirmare a calificării de asistent medical.{linebreak} - Cunoaşterea de calculatorului.{linebreak} - Sârguință și politețe.",

    // FAQ Page
    "faq_page.title": "Întrebări frecvente",
    "faq_page.question_0": "Este acces la internet în locuinţă?",
    "faq_page.answer_0": "Da, apartamentele au internet gratuit.",
    "faq_page.question_1": "Care este salariul net?",
    "faq_page.answer_1":
      "În funcție de proiect, în medie 1400-1500 euro + 25% pentru orele suplimentare și munca de noapte (23: 00-06: 00) plus + 100% la sărbători.",
    "faq_page.question_2": "Care este orarul de muncă?",
    "faq_page.answer_2":
      "În funcție de proiect, ziua de lucru variază de obicei între 8 și 10 ore. Se lucrează în schimburi – prima tură 06: 00-14: 30; A doua tura - 14: 30-23: 00 (noaptea de la 23:00 la 06:00).",
    "faq_page.question_3": "Este posibil de lucrat în weekend şi de sărbători?",
    "faq_page.answer_3": "În timpul sezonului cînd sunt volume mari – se lucrează şi sâmbăta, de obicei 6 ore pe zi.",
    "faq_page.question_4": "Este posibil de lucrat ore suplimentare şi cum se achită acestea?",
    "faq_page.answer_4":
      "Munca suplimentară este posibilă numai atunci când este necesar - în conformitate cu nevoile proiectului. Supraorele se achită cu 25% mai mult.",
    "faq_page.question_5": "Contractele de muncă sunt moldoveneşti sau germane?",
    "faq_page.answer_5":
      "Oferim doar contract de muncă german - acesta se încheie cu firma germană HAM-LOG-GRUPPE. Contractul prevede toate garanțiile sociale existente în Germania.",

    "faq_page.question_6": "Este necesară cunoşterea limbii germane?",
    "faq_page.answer_6":
      "Pentru lucru în depozite nu este obligatoriu cunoaşterea limbii germane, şefii de proiecte fiind vorbitori de limbă rusă şi engleză, totodată cunoaşterea limbii germane este un avantaj care vă oferă posibilitatea de creştere în carieră.",
    "faq_page.question_7": "Se oferă transport către Germania?",
    "faq_page.answer_7":
      "Puteți pleca în Germania desinestătător cu mașina personală, avionul sau microbuzul. Compania noastră vă acordă support şi Vă recomandă serviciile transportatorilor cu care colaborează deoarece transportarea este efectuată de către şoferi experimentaţi care Vă vor aduce la destinaţie direct la locul de cazare, fără taxe suplimentare",
    "faq_page.question_8": "Program de instruire pentru colectarea mărfurilor după comenzi vocale:",
    "faq_page.answer_8": "https://www.youtube.com/watch?v=6J9Xg1dSLJ0#action=share ",
    "faq_page.question_9": "Cît costă recrutarea şi ce comisioane se percep pentru angajare?",
    "faq_page.answer_9":
      "Recrutarea este gratuită, nu se percepe nici un tip de comision, deoarece compania Jobs Agency este reprezentantul oficial al companiei HAM-LOG-GRUPPE, în Republica Moldova.",
    "faq_page.question_10": "ОПЛАЧИВАЮТСЯ ЛИ ТРАСПОРТНЫЕ РАСХОДЫ?	",
    "faq_page.answer_10":
      "Компания компенсирует работникам первые транспортные расходы из Литвы в Германию. 100Eur компенсация выплачивается вместе с зарплатой через 3 месяца работы в компании. ",
    "faq_page.question_11": "Ce fel de cazare se oferă şi care sunt costurile?",
    "faq_page.answer_11":
      "Angajații sunt cazați în apartamente sau case închiriate de companie, cu toate comodităţile, tehnica de uz casnic și mobilierul necesar, de obicei 2 persoane în odaie. Costurile de cazare variază între 120-150 EURO, în dependenţă de proiect şi se deduc lunar din salariu.",
    "faq_page.question_12": "Se oferă haine şi încălţăminte de lucru?",
    "faq_page.answer_12":
      "Compania vă oferă îmbrăcămintea de protecţie de care aveți nevoie pentru muncă, cu excepția încălţămintei. Încălţămintea de lucru este achiziționată desinestătător, ea trebuie să fie cu protecție metalică a degetelor la picioare. Compania compensează costul încălţămintei în mărime de 40 de euro, care se achită împreună cu salariul după 1 lună de muncă.",
    "contacts_page.title": "Contacte",

    "contact_form.inputs_legend_required": "Toate Câmpurile marcate cu ' * ' sunt obligatorii.",
    "contact_form.first_name_input.title": "Nume",
    "contact_form.last_name_input.title": "Familia",
    "contact_form.citizenship_input.title": "Cetățenie",
    "contact_form.tel_input.title": "Numar Telefon",
    "contact_form.job_input.title": "Loc de muncă",
    "contact_form.submit_button.label": "Trimite cererea",
  },
};
