import { LOCALES } from "../locales";

// eslint-disable-next-line
export default {
  [LOCALES.RUSSIAN]: {
    "global_msg.thank_you": "Спасибо за заявку, мы свяжемся с вами в ближайшее время.",
    "header.nav_home": "НАЧАЛО",
    "header.nav_about": "О НАС",
    "header.nav_joblist": "ОБЪЯВЛЕНИЯ",
    "header.nav_faq": "ЧЗВ",
    "header.nav_contacts": "КОНТАКТЫ",

    "home_page.title": "РАБОТА В ГЕРМАНИИ",
    "home_page.vacantions_list_title": "В ДАННЫЙ МОМЕНТ ТРЕБУЮТСЯ",
    "home_page.vacantions_job_1": "СБОРЩИКИ ЗАКАЗОВ",
    "home_page.vacantions_job_2": "ГРУЗЧИКИ-СОРТИРОВЩИКИ НА СКЛАДЕ НАПИТКОВ",
    "home_page.vacantions_job_3": "БРИГАДИРЫ",
    "home_page.all_jobs_button": "ВСЕ ОБЪЯВЛЕНИЯ",
    "home_page.benefits_list_title": "ПРЕИМУЩЕСТВО РАБОТЫ С НАМИ",
    "home_page.benefit_1": "КАЧЕСТВО",
    "home_page.benefit_desc_1":
      "Мы ориентированы на стандарты труда, обеспечивающие эффективность управления качеством. Мы стремимся соответствовать ожиданиям наших клиентов и сотрудников.",
    "home_page.benefit_2": "ЧЕСТНОСТЬ",
    "home_page.benefit_desc_2":
      "Придерживаться этических нормы мы считаем обязанностью. Наша деятельность основана на честности и соответствии с действующим законодательством.",
    "home_page.benefit_3": "ЛОЯЛЬНОСТЬ",
    "home_page.benefit_desc_3":
      "Мы ценим лояльные отношения с нашими сотрудниками, клиентами и зарубежными партнерами.",
    "home_page.benefit_4": "ОТВЕТСТВЕННОСТЬ",
    "home_page.benefit_desc_4": "Мы понимаем нашу социальную ответственность и придерживаемся основных ценностей.",
    "home_page.contacts_title": "Контакты",

    "about_page.title": "О НАС",
    "about_page.subtitle": "Миссия Jobs Agency",
    "about_page.desc":
      "Компания, предоставляющая широкий спектр услуг в области управления персоналом.{linebreak}Компания по подбору и трудоустройству персонала, которая имеет множество предложений работы за рубежом.",

    "about_page.subtitle_1": "Почему Jobs Agency?",
    "about_page.desc_1":
      "- Мы являемся компанией, имеющей лицензию на деятельность по подбору персонала и трудоустройству за рубежом.{linebreak} - Наша деятельность авторизирована Министерством здравоохранения, труда и социальной защиты, наши контракты зарегистрированы в АНОФ{linebreak} - Мы имеем опытную команду в области управления персоналом, которая обеспечивает качественную поддержку на протяжении действия контракт{linebreak} - Компания напрямую сотрудничает с надежными иностранными партнерами-работодателями без удержания комиссионных за посредничество",

    "about_page.benefits_title": "Мы оказываем услуги в сфере управления персоналом",
    "about_page.benefits_desc":
      "- Aутсорсинг управления персоналом: комплексный / частичный{linebreak} - Консультации в области менеджмента человеческих ресурсов :{linebreak} - Создание подразделения HR{linebreak} - Разработка нормативных актов по управлению человеческими ресурсами на уровне подразделения{linebreak} - Кадровое делопроизводство{linebreak} - Соблюдение трудового законодательства{linebreak} - Подбор персонала{linebreak} - Адаптация{linebreak} - Мотивация, вознаграждение и оценка работы сотрудников{linebreak} - Нормирование труда{linebreak} - Разработка должностных инструкций{linebreak} - Обучение персонала{linebreak} - Планы преемственности и кадровый резерв{linebreak} - Охрана труда{linebreak} - Aудит HR{linebreak} - Развитие корпоративной культуры{linebreak} - Развитие бренда работодателя",

    "about_page.benefits_title_1": "Оказываем услуги по подбору и трудоустройству за рубежом",
    "about_page.benefits_desc_1":
      "Наши партнеры в Германии{linebreak}{linebreak}HAM-LOG-GRUPPE Holding GmbH, компания, предоставляющая услуги логистики в Германии и других странах Европы в соответствии с высочайшими стандартами и ценностями. HAM-LOG-GRUPPE Holding GmbH имеет более 60 проектов по всей Германии, от Гамбурга до Мюнхена. С сотрудниками заключаются немецкие трудовые договоры, которые предлагают все виды социальных гарантий в Германии, а также возможность получения пенсии и пособий на ребенка. Компания предлагает сотрудникам все возможности для карьерного роста: многие стали реководителями, начав карьеру в компании с нижней ступени.",

    "joblist_page.title": "ОБЪЯВЛЕНИЯ",
    "joblist_page.action_button": "Я хочу работать",

    // JOB LIST OFFERS
    // Order Collector
    "joblist_page.job.order_collector.title": "Cборщики заказов на складе напитков",
    "joblist_page.job.order_collector.desc":
      "Специфика работы – сбор и комплектовка товаров с использованием системы речевого бронирования. Работа с электрической тележкой.",

    // Empty Container Sorter
    "joblist_page.job.empty_container_sorter.title": "Сортировщик пустой тары",
    "joblist_page.job.empty_container_sorter.desc":
      "Пустые контейнеры (бутылки и коробки) точно сортируются и собираются в соответствии со стандартом.",

    // Truck Driver
    "joblist_page.job.truck_driver.title": "Водители для грузового транспорта",
    "joblist_page.job.truck_driver.desc":
      "Вождение грузовика, своевременная транспортировка товаров из логистических складов в супермаркеты Германии.",

    // Project manager
    "joblist_page.job.project_manager.title": "Руководитель проекта",
    "joblist_page.job.project_manager.desc": "Управление и координирование комады (100-150 работников)",

    // Warehouse Order Collector
    "joblist_page.job.warehouse_order_collector.title": "Сборщики заказов на складе товаров",
    "joblist_page.job.warehouse_order_collector.desc":
      "Специфика работы – с помощью сканера собирать товары по заказу. Работа с электрической тележкой. <b>1600 euro</b>",

    // Loader Driver
    "joblist_page.job.loader_driver.title": "Водитель осевого погрузчика",
    "joblist_page.job.loader_driver.desc":
      "Специфика работы – разгрузка / загрузка грузовых автомобилей, складирование товаров или пустой тары.",

    // High Rise Loader Driver
    "joblist_page.job.high_rise_loader_driver.title": "Водители высотных погрузчиков",
    "joblist_page.job.high_rise_loader_driver.desc":
      "Разгрузка / загрузка грузовых автомобилей, складирование товаров (на высоте до 10 м). Фиксирование поступающих товаров и нарушения их упаковки.Все новые сотрудники проходят инструктаж и ознакомление с рабочим место",

    // Warehouse Workers
    "joblist_page.job.warehouse_workers.title": "Подсобные работники склада",
    "joblist_page.job.warehouse_workers.desc":
      "Работник склада несет ответственность за порядок и чистоту склада во время всего рабочего дня: собирает пустые палеты, картон и пленку. Работа с электрической тележкой.",

    // Project Coordinators
    "joblist_page.job.project_coordinators.title": "Координаторы проектов",
    "joblist_page.job.project_coordinators.desc":
      "В ответственность входит: координация работ и сотрудников (до 50 работников); составление графиков работы и отпусков, распределение и планирование задач, функций.",

    // Furniture Movers
    "joblist_page.job.furniture_movers.title": "Грузчики на складе мебели",
    "joblist_page.job.furniture_movers.desc":
      "Работа с товарами: разгрузка товаров из прицепа при помощи электропогрузчика или электрической тележки, сообщение руководству. <b>1500 euro</b>",

    // Foremen
    "joblist_page.job.foremen.title": "Бригадиры",
    "joblist_page.job.foremen.desc":
      "В ответственность бригадира входит составление рабочих графиков, распределение и планирование работ.",

    // Medical Assistant
    "joblist_page.job.medical_assistant.title": "Медсестра",
    "joblist_page.job.medical_assistant.desc":
      "Доврачебная медицинская помощь. Уход за пациентами. Ассистирование врачам, стерилизация инструментов",

    // JOB DETAILS TITLES
    "jobdetails.job_type_title": "Тип работы:",
    "jobdetails.job_benefits_title": "Компания предлагает:",
    "jobdetails.job_requires_title": "Требования:",
    "jobdetails.job_contact_title": "Свяжитесь с нами",

    // JOB DETAILS OFFERS
    // Order Collector
    "jobdetails.job_type.order_collector.desc":
      "Сбор и комплектовка товаров с использованием системы речевого бронирования через наушники. Работа с электрической тележкой. Проверка выбранных товaров и заказов и подготовка к транспортировке. Все новые сотрудники проходят инструктаж и ознакомление с рабочим местом.",
    "jobdetails.job_benefits.order_collector.desc":
      "Немецкий трудовой договор и все социальные гарантии; Стабильное рабочее время, стабильная заработная плата, рассчитанная на основе производительности; Обучение на рабочем месте; Гибкий график отпусков; Обеспечение рабочей одеждой. Оплата за обувь, которую вы покупаете самостоятельно. (40 евро переводится на ваш банковский счет с заработной платой через месяц ); Первые командировочные расходы из Молдавии в Германию возвращаются. (100 евро переводится на ваш банковский счет с зарплатой через месяц ); Предоставляются номера со всеми удобствами, доступ в Интернет рядом с рабочей зоной (до 15 км). (плата за коммунальные услуги в размере 75 евро / месяц вычитается из заработной платы); Заработная плата <b>Зарплата от  1300 евро</b>  (после уплаты налогов) и зависит от результата. Бонусы за перевыполнение плана. Авансовый платеж в соответствии с потребностями и договоренностью.",
    "jobdetails.job_requires.order_collector.desc":
      "- Опыт работы; {linebreak} - Хорошее физическое здоровье; {linebreak} - Ответственность, трудолюбие; {linebreak} - Отсутствие вредных привычек; {linebreak} - Автомобиль для поездок на / с работы (преимущество); {linebreak} - Для получения дополнительной информации о проекте и условиях работы свяжитесь с нашими менеджерами по отбору.",

    // Empty Container Sorter
    "jobdetails.job_type.empty_container_sorter.desc":
      "Пустые контейнеры (бутылки и коробки) точно сортируются и собираются в соответствии со стандартом. Сортировка тары (ящиков и бутылок) по производителем, форме и цвету. Работа в команде, работа в разных рабочих группах в течении дня.Все новые сотрудники проходят инструктаж и ознакомление с рабочим местом.",
    "jobdetails.job_benefits.empty_container_sorter.desc":
      "Немецкий трудовой договор и все социальные гарантии; {linebreak} Стабильное рабочее время, стабильная заработная плата; {linebreak} Обучение на рабочем месте; {linebreak} Гибкий график отпусков; {linebreak} Обеспечение рабочей одеждой. Опата за обувь, которую вы покупаете самостоятельно.(40 евро переводится на ваш банковский счет с заработной платой через месяц ); {linebreak} Первые командировочные расходы из Молдавии в Германию возвращаются. (100 евро переводится на ваш банковский счет с зарплатой через месяц ); {linebreak} Предоставляются номера со всеми удобствами, доступ в Интернет рядом с рабочей зоной.(плата за коммунальные услуги в размере 100 евро / месяц вычитается из заработной платы);{linebreak} <b>Заработная платаЗарплата от 1400 евро</b> (после уплаты налогов) и зависит от результата. Бонусы за перевыполнение плана.Авансовый платеж в соответствии с потребностями и договоренностью.",
    "jobdetails.job_requires.empty_container_sorter.desc":
      "- Выносливость, быстрый темп работы; {linebreak} - Хорошее физическое здоровье (преимущество); {linebreak} - Отсутствие вредных привычек; {linebreak} - Способность работать в команде; {linebreak} - Автомобиль для поездок на / с работы (преимущество); {linebreak} - Для получения дополнительной информации о проекте и условиях работы свяжитесь с нашими менеджерами по отбору.",

    // Truck Driver
    "jobdetails.job_type.truck_driver.desc":
      "Управление грузовым транспортом (с / без прицепа). Загрузка или разгрузка товаров на складе, доставка заказчику, разгрузка в указанном месте, проверка количества товаров согласно путевому листу. Загрузка пустой тары, проверка соответствие факта документам, доставка обратно на склад. Фиксация любого несоответствия или брака, сообщение руководству. Загрузка или разгрузка производится при помощи электрической тележки, которая возится с собой.",
    "jobdetails.job_benefits.truck_driver.desc":
      "Немецкий трудовой договор и все социальные гарантии; {linebreak}Стабильное рабочее время, стабильная заработная плата; {linebreak} Обучение на рабочем месте; {linebreak} Гибкий график отпусков; {linebreak} Обеспечение рабочей одеждой. Опата за обувь, которую вы покупаете самостоятельно.(40 евро переводится на ваш банковский счет с заработной платой через месяц ); {linebreak} Первые командировочные расходы из Молдавии в Германию возвращаются.(100 евро переводится на ваш банковский счет с зарплатой через месяц ); {linebreak} Предоставляются номера со всеми удобствами, доступ в Интернет рядом с рабочей зоной.(плата за коммунальные услуги в размере 100 евро / месяц вычитается из заработной платы); {linebreak} <b>Зарплата от 2000 евро</b> (после уплаты налогов), зависит от результатов работы. Предусмотрены бонусы за переработку. По договоренности возможна авансовая оплата.",
    "jobdetails.job_requires.truck_driver.desc":
      "- Обязательно водительское удостоверение на управление грузового траспорта (с / без прицепа); {linebreak} - Опыт работы; {linebreak} - Ответственное отношение к работе; {linebreak} - Внимательность и быстрая ориентация; {linebreak} - Хорошее физическое здоровье; {linebreak} - Отсутствие вредных привычек; {linebreak} - Автомобиль для поездок на / с работы (преимущество);{linebreak} - Для получения дополнительной информации о проекте и условиях работы свяжитесь с нашими менеджерами по отбору.",

    // Project manager
    "jobdetails.job_type.project_manager.desc":
      "- Руководство и организация работы команды (100-150 сотрудников);{linebreak}- Презентация компании в проектах с клиентом;{linebreak} - Общение и решение текущих проблем с руководителями и клиентами; {linebreak}- Высокое качество и эффективная организация работы и управления процессами;{linebreak} - Планирование и анализ расходов-бюджета проекта;{linebreak} - Планирование работ и потребности в работниках;{linebreak} - Содействие персоналу в государственных учреждениях (при необходимости);{linebreak} - Формирование команды, обучение, интеграция новых сотрудников; {linebreak} - Обеспечение новых проектов, посещение объектов.",
    "jobdetails.job_benefits.project_manager.desc":
      "- Немецкий трудовой договор;{linebreak} - Динамичную и ответственную работу в международной компании;{linebreak} - Конкурентную оплату труда (от 2000 Eur нетто);{linebreak} - Жилье в Германии (работодатель оплачивает аренду); {linebreak}- Автомобиль.",
    "jobdetails.job_requires.project_manager.desc":
      "- Хорошее знание (письменное и устное) немецкого языка (не ниже уровня B2);{linebreak} - Организационные способности, способность объединять и мотивировать команду;{linebreak} - Отличные навыки планирования, организации и управления; {linebreak}- Профессионализм в общении с сотрудниками и клиентами;{linebreak} - Быстрый и четкая реакция при решении проблем;{linebreak} - аналитическое, логическое мышление, опыт расчета производительности;{linebreak} - Самостоятельность и ответственность;{linebreak} - Честность и самомотивация на достижение результатов;{linebreak} - Навыки вождения автомобиля.",

    // Warehouse Order Collector
    "jobdetails.job_type.warehouse_order_collector.desc":
      "Сбор и комплектовка товаров с использованием сканеров. Работа с электрической тележкой. Проверка выбранных товаров и заказов и подготовка к транспортировке. Работа с Shell производимой продукцией - масла (от самого маленького пакета до 200 л бака).",
    "jobdetails.job_benefits.warehouse_order_collector.desc":
      "Немецкий трудовой договор и все социальные гарантии;{linebreak} Стабильное рабочее время, стабильная заработная плата, зависящая от результатов;{linebreak} Обучение на рабочем месте;{linebreak} Гибкий график отпусков;{linebreak} Обеспечение рабочей одеждой. Опата за обувь, которую вы покупаете самостоятельно. (40 евро переводится на ваш банковский счет с заработной платой через месяц );{linebreak} Первые командировочные расходы из Молдавии в Германию возвращаются.(100 евро переводится на ваш банковский счет с зарплатой через месяц );{linebreak} Предоставляется жилье со всеми удобствами, доступ в Интернет рядом с рабочей зоной (до 15 км). (плата за коммунальные услуги в размере 75 евро / месяц вычитается из заработной платы);{linebreak} <b>Заработная плата от 1300 евро</b> (после уплаты налогов) и зависит от результата. Бонусы за перевыполнение плана.{linebreak} Авансовый платеж в соответствии с потребностями и договоренностью.",
    "jobdetails.job_requires.warehouse_order_collector.desc":
      "- Опыт работы (преимущество);{linebreak} - Хорошее физическое здоровье;{linebreak} - Ответственность, быстрая ориентация;{linebreak} - Отсутствие вредных привычек;{linebreak} - Автомобиль для поездок на / с работы (преимущество);{linebreak} - Для получения дополнительной информации о проекте и условиях работы свяжитесь с нашими менеджерами по выбору.",

    // Loader Driver
    "jobdetails.job_type.loader_driver.desc":
      "Разгрузка / загрузка грузовых автомобилей, складирование товаров. Запись поступающих товаров и нарушения их упаковки.Работа с газовыми или электрическими многоосевыми погрузчиками: двухосевые, четырехосевые, шестиосевые. Все новые сотрудники проходят инструктаж и ознакомление с рабочим местом.",
    "jobdetails.job_benefits.loader_driver.desc":
      "Немецкий трудовой договор и все социальные гарантии;{linebreak} Стабильное рабочее время, стабильная заработная плата;{linebreak} Обучение на рабочем месте;{linebreak} Гибкий график отпусков;{linebreak} Обеспечение рабочей одеждой. Опата за обувь, которую вы покупаете самостоятельно. (40 евро переводится на ваш банковский счет с заработной платой через месяц );{linebreak} Первые командировочные расходы из Молдавии в Германию возвращаются.(100 евро переводится на ваш банковский счет с зарплатой через месяц );{linebreak} Предоставляются номера со всеми удобствами, доступ в Интернет рядом с рабочей зоной.(плата за коммунальные услуги в размере 100 евро / месяц вычитается из заработной платы);",
    "jobdetails.job_requires.loader_driver.desc":
      "- Обязательно водительское удостоверение на управление aвтопогрузчиком;{linebreak} - Опыт работы;{linebreak} - Внимательность и быстрая ориентация;{linebreak} - Хорошее физическое здоровье ;{linebreak} - Отсутствие вредных привычек;{linebreak} - Способность работать в команде;{linebreak} - Автомобиль для поездок на / с работы (преимущество);{linebreak} - Для получения дополнительной информации о проекте и условиях работы свяжитесь с нашими менеджерами по отбору.",

    // High Rise Loader Driver
    "jobdetails.job_type.high_rise_loader_driver.desc":
      "Разгрузка / загрузка грузовых автомобилей, складирование товаров (на высоте до 10 м). Фиксирование поступающих товаров и нарушения их упаковки.Все новые сотрудники проходят инструктаж и ознакомление с рабочим место",
    "jobdetails.job_benefits.high_rise_loader_driver.desc":
      "Немецкий трудовой договор и все социальные гарантии; {linebreak}Стабильное рабочее время, стабильная заработная плата; {linebreak}Обучение на рабочем месте; {linebreak}Гибкий график отпусков; {linebreak}Обеспечение рабочей одеждой. Опата за обувь, которую вы покупаете самостоятельно.(40 евро переводится на ваш банковский счет с заработной платой через месяц );{linebreak} Первые командировочные расходы из Молдавии в Германию возвращаются.(100 евро переводится на ваш банковский счет с зарплатой через месяц );{linebreak} Предоставляются номера со всеми удобствами, доступ в Интернет рядом с рабочей зоной.(плата за коммунальные услуги в размере 100 евро / месяц вычитается из заработной платы); {linebreak} <b>Зарплата от 1500 евро </b> (после уплаты налогов), зависит от результатов работы. Предусмотрены бонусы за переработку.По договоренности возможна авансовая оплата.",
    "jobdetails.job_requires.high_rise_loader_driver.desc":
      "- Обязательно водительское удостоверение на управление aвтопогрузчиком;{linebreak} - Опыт работы с высотным погрузчиком;{linebreak} - Внимательность и быстрая ориентация;{linebreak} - Хорошее физическое здоровье ;{linebreak} - Отсутствие вредных привычек;{linebreak} - Способность работать в команде;{linebreak} - Автомобиль для поездок на / с работы (преимущество);{linebreak} - Для получения дополнительной информации о проекте и условиях работы свяжитесь с нашими менеджерами по отбору.",

    // Warehouse Workers
    "jobdetails.job_type.warehouse_workers.desc":
      "Разгрузка / загрузка грузовых автомобилей, складирование товаров (на высоте до 10 м). Фиксирование поступающих товаров и нарушения их упаковки.Все новые сотрудники проходят инструктаж и ознакомление с рабочим место",
    "jobdetails.job_benefits.warehouse_workers.desc":
      "Немецкий трудовой договор и все социальные гарантии;{linebreak} Стабильное рабочее время, стабильная заработная плата; {linebreak} Обучение на рабочем месте; {linebreak} Гибкий график отпусков; {linebreak} Обеспечение рабочей одеждой. Опата за обувь, которую вы покупаете самостоятельно.(40 евро переводится на ваш банковский счет с заработной платой через месяц ); {linebreak}Первые командировочные расходы из Молдавии в Германию возвращаются.(100 евро переводится на ваш банковский счет с зарплатой через месяц );Предоставляются номера со всеми удобствами, доступ в Интернет рядом с рабочей зоной.(плата за коммунальные услуги в размере 100 евро / месяц вычитается из заработной платы);{linebreak} <b>Зарплата от 1300 евро</b> (после уплаты налогов), зависит от результатов работы. Предусмотрены бонусы за переработку.По договоренности возможна авансовая оплата.",
    "jobdetails.job_requires.warehouse_workers.desc":
      " - Внимательность и быстрая ориентация;{linebreak} - Хорошее физическое здоровье ;{linebreak} - Отсутствие вредных привычек;{linebreak} - Способность работать в команде;{linebreak} - Для получения дополнительной информации о проекте и условиях работы свяжитесь с нашими менеджерами по отбору.",

    // Project Coordinators
    "jobdetails.job_type.project_coordinators.desc":
      "В ответственность входит: координация работ и сотрудников (до 40 работников); составление графиков работы и отпусков, распределение и планирование задач, функций; эффективная и качественная организация работ на логистическом складе и управление процессами; прием заказов; планирование работ; повышение производительности; ведение документации; инструктирование работников; помощь рабочим в общении на немецком языке; встреча новых сотрудников, размещение, организация прибытия на работу; обслуживание жилья; регистрация рабочих в Германии; доставка в медицинское учреждение в случае ухудшения здоровья сотрудника. Общение с начальником смены на складе на немецком языке. Все новые сотрудники проходят инструктаж и ознакомление с рабочим местом.",
    "jobdetails.job_benefits.project_coordinators.desc":
      "Динамичную и ответственную работу в международной компании; {linebreak}Жилье в Германии; {linebreak}Возможность периодических отпусков;{linebreak} Хорошие условия труда;Возможность карьерного роста для мотивированных сотрудников; {linebreak}Конкуретная оплата труда. {linebreak} <b>Зарплата от 1600 евро </b>(после уплаты налогов), зависит от результатов работы.С доплатой за результаты (бонусная система)",
    "jobdetails.job_requires.project_coordinators.desc":
      " - Опыт работы в области логистики, транспорта; {linebreak} - Хорошие знания немецкого языка письменно и устно (не ниже уровня B2); {linebreak} - Управленческий опыт, умение объединять и мотивировать команду; {linebreak} - Планирование задач и их распределение; {linebreak} - Быстрота и гибкость в решении проблем;{linebreak} - Собственный автомобиль.",

    // Furniture Movers
    "jobdetails.job_type.furniture_movers.desc":
      "Работа с товарами: разгрузка товаров из прицепа при помощи электропогрузчика или электрической тележки; сортировка и сканирование товаров (также как происходит прием товаров), фиксирование нарушения упаковок или порчи товаров, сообщение руководству. Отбор товаров в соответствии с заказом, заполнение путевого листа, и складирование в месте погрузки. Загрузка товаров в грузовой автомобиль. Обеспечить своевременный качественный сбор товаров.Все новые сотрудники проходят инструктаж и ознакомление с рабочим местом.",
    "jobdetails.job_benefits.furniture_movers.desc":
      "Немецкий трудовой договор и все социальные гарантии; {linebreak}Стабильное рабочее время, стабильная заработная плата;{linebreak}Обучение на рабочем месте;{linebreak}Гибкий график отпусков;{linebreak}Обеспечение рабочей одеждой. Опата за обувь, которую вы покупаете самостоятельно.(40 евро переводится на ваш банковский счет с заработной платой через месяц );{linebreak}Первые командировочные расходы из Молдавии в Германию возвращаются.(100 евро переводится на ваш банковский счет с зарплатой через месяц );{linebreak}Предоставляются номера со всеми удобствами, доступ в Интернет рядом с рабочей зоной.(плата за коммунальные услуги в размере 100 евро / месяц вычитается из заработной платы);{linebreak} <b>Зарплата от 1500 евро</b> (после уплаты налогов), зависит от результатов работы. Предусмотрены бонусы за переработку.По договоренности возможна авансовая оплата.",
    "jobdetails.job_requires.furniture_movers.desc":
      "- Опыт работы;{linebreak} - Хорошее физическое здоровье;{linebreak} - Внимательность и быстрая ориентация;Ответственность;{linebreak} - Отсутствие вредных привычек;{linebreak} - Автомобиль для поездок на / с работы (преимущество);{linebreak} - Знания немецкого языка - необязательны;",

    // Foremen
    "jobdetails.job_type.foremen.desc":
      "В ответственность бригадира входит составление рабочих графиков, распределение и планирование работ, функций; поддержание порядка на складе, оперативное решение проблем; эффективная и качественная организация работ на логистическом складе и управление процессами. А также: контроль над выполнением задач и нормативов, обеспечить соответствие требованиям безопасности труда. Общение с начальником смены на складе на немецком языке.Все новые сотрудники проходят инструктаж и ознакомление с рабочим местом.",
    "jobdetails.job_benefits.foremen.desc":
      "Динамичную и ответственную работу в международной компании;{linebreak}Жилье в Германии;Возможность периодических отпусков;{linebreak}Хорошие условия труда;Возможность карьерного роста для мотивированных сотрудников;{linebreak}Конкуретная оплата труда.Заработная плата:Договорная;{linebreak}С доплатой за результаты (бонусная система).",
    "jobdetails.job_requires.foremen.desc":
      "- Опыт работы в области логистики, транспорта;{linebreak} - Хорошие знания немецкого языка письменно и устно (не ниже уровня B1);{linebreak} - Управленческий опыт, умение объединять и мотивировать команду;{linebreak} - Планирование задач и их распределение;{linebreak} - Быстрота и гибкость в решении проблем;{linebreak} - Собственный автомобиль.",

    // Medical Assistant
    "jobdetails.job_type.medical_assistant.desc":
      "Доврачебная медицинская помощь.Уход за пациентами.Ассистирование врачам, стерилизация инструментов.Забор биологического материала для исследования.Исполнение врачебных назначений.Руководство младшим медперсоналом.Утилизация медицинских отходов.",
    "jobdetails.job_benefits.medical_assistant.desc":
      "Немецкий трудовой договор;{linebreak}Динамичную и ответственную работу в международной компании;{linebreak}<b>Конкурентную оплату труда (от 1950 Eur)</b>;",
    "jobdetails.job_requires.medical_assistant.desc":
      "- Хорошее знание (письменное и устное) немецкого языка (не ниже уровня B1);{linebreak} - Среднее медицинское образование;{linebreak} - Действующий сертификат по сестринскому делу.{linebreak} - Навыки работы с ПК.{linebreak} - Исполнительность и вежливость",

    // FAQ Page
    "faq_page.title": "ЧЗВ",
    "faq_page.question_0": "ЕСТЬ ЛИ ИНТЕРНЕТ НА МЕСТЕ ЖИТЕЛЬСТВА?",
    "faq_page.answer_0": "Да, в квартирах есть интернет бесплатный.",
    "faq_page.question_1": "НАДО ЛИ ЗНАТЬ НЕМЕЦКИЙ ЯЗЫК?",
    "faq_page.answer_1":
      "Знание немецкого языка необязательно (если только вы не хотите подняться по карьерной лестнице). Все руководители проектов - русскоговорящие. ",
    "faq_page.question_2": "КАКУЮ ЗАРПЛАТУ ПРЕДЛАГАЕТЕ?",
    "faq_page.answer_2":
      "В зависимости от проекта в среднем 1400-1500 евро + 25% за сверхурочную и ночную работу (23:00 - 06:00) плюс +100% в праздничные дни.",
    "faq_page.question_3": "КАКИЕ РАБОЧИЕ ЧАСЫ?",
    "faq_page.answer_3":
      "В зависимости от проекта рабочий день обычно составляет от 8 до 10 часов. Работа в сменах - 1 смена 06:00-14:30; 2 смена - 14: 30-23: 00 (ночное время с 23:00 до 06:00).",
    "faq_page.question_4": "НУЖНО ЛИ РАБОТАТЬ В ВЫХОДНЫЕ И ПРАЗДНИЧНИЕ ДНИ?",
    "faq_page.answer_4": "Во время сезона - да: работа по субботам, обычно 6 часов в день.",
    "faq_page.question_5": "МОЖНО ЛИ БРАТЬ СВЕРХУРОЧНЫЕ, ОПЛАЧИВАЮТСЯ ЛИ ОНИ?",
    "faq_page.answer_5":
      "Сверхурочная работа возможна только при необходимости - в соответствии с потребностями проекта. Оплачивается на 25% больше почасовой ставки",
    "faq_page.question_6": "Трудовые договоры - немецкие или молдавские?",
    "faq_page.answer_6":
      "Мы предлагаем только немецкий трудовой договор - они заключаются с нашей немецкой компанией HAM-LOG-GRUPPE. Вам будут предоставлены все социальные гарантии, которые предусмотрены в Германии.",
    "faq_page.question_7": "НА КАКОМ ЯЗЫКЕ ГОВОРЯТ РУКОВОДИТЕЛИ ПРОЕКТОВ?",
    "faq_page.answer_7":
      "Все наши руководители проектов, бригадиры, начальники смен - русскоговорящие, с ними вы договоритесь на английском и русском.",
    "faq_page.question_8": "ОБУЧАЮЩАЯ ПРОГРАММА ДЛЯ СБОРА ЗАКАЗОВ ГОЛОСОВЫМИ КОМАНДАМИ",
    "faq_page.answer_8": "https://www.youtube.com/watch?v=6J9Xg1dSLJ0#action=share ",
    "faq_page.question_9": "СКОЛЬКО СТОИТ РЕКРУТИНГ?",
    "faq_page.answer_9":
      "Рекрутинг бесплатный, так как Jobs Agency SRL - это представитель холдинга в Молдавии, который занимается отбором персонала.",
    "faq_page.question_10": "ОПЛАЧИВАЮТСЯ ЛИ ТРАСПОРТНЫЕ РАСХОДЫ?	",
    "faq_page.answer_10":
      "Компания компенсирует работникам первые транспортные расходы из Молдавии в Германию. 100Eur компенсация выплачивается вместе с зарплатой через месяц работы в компании. ",
    "faq_page.question_11": "КАКУЮ ЖИЛПЛОЩАДЬ ПРЕДОСТАВЛЯЮТ РАБОТНИКАМ?",
    "faq_page.answer_11":
      "Сотрудники размещаются в квартирах или домах, арендуемых компанией, со всеми удобствами, бытовой техникой и мебелью. ",
    "faq_page.question_12": "ПРЕДОСТАВЛЯЕТСЯ ЛИ РАБОЧАЯ ОДЕЖДА",
    "faq_page.answer_12":
      "Да, мы предоставляем всю необходимую для работы одежду, кроме обуви. Но компания оплачивает расходы на рабочие ботинки, которые приобретаются самостоятельно (необходимо носить специальные ботинки с защитой носка). Компенсация 40 евро выплачивается вместе с зарплатой через месяц работы в компании.",

    "contact_form.inputs_legend_required": "Все поля помеченные ' * ' с обязательные.",
    "contacts_page.title": "Контакты",
    "contact_form.first_name_input.title": "Имя",
    "contact_form.last_name_input.title": "Фамилия",
    "contact_form.citizenship_input.title": "Гражданство",
    "contact_form.tel_input.title": "Номер телефона ",
    "contact_form.job_input.title": "Работа",
    "contact_form.submit_button.label": "Отправить заявку",
  },
};
