import React from "react";
import { FormattedMessage } from "react-intl";

import { Contact } from "components";
import { ContentBlock, PageTitleSlot } from "ui-kit";

export default function ContactsPage() {
  return (
    <div className="contacts-page route-page">
      <PageTitleSlot>
        <h3 className="route-page__title">
          <FormattedMessage id="contacts_page.title" />
        </h3>
      </PageTitleSlot>
      <ContentBlock>
        <Contact />
      </ContentBlock>
    </div>
  );
}
