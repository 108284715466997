import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";

import Grid from "@mui/material/Grid";
import { JobItemCard } from "ui-kit";

export default function JobsList({ jobsList }) {
  return jobsList.map(({ job_id, job_title, job_desc, job_cover }, index) => (
    <Grid item xs={12} md={6} lg={4} key={index}>
      <JobItemCard>
        <JobItemCard.CoverImage src={job_cover} />
        <JobItemCard.Content>
          <JobItemCard.Title>
            <FormattedMessage id={job_title} />
          </JobItemCard.Title>
          <JobItemCard.Text textTitle={<FormattedMessage id={job_desc} />}>
            <FormattedMessage id={job_desc} values={{ b: (...chunks) => <b>{chunks}</b>, linebreak: <br /> }} />
          </JobItemCard.Text>
          <JobItemCard.ActionButton to={`jobslist/${job_id}`}>
            <FormattedMessage id="joblist_page.action_button" />
          </JobItemCard.ActionButton>
        </JobItemCard.Content>
      </JobItemCard>
    </Grid>
  ));
}

JobsList.prototype = {
  jobsList: PropTypes.array.isRequired,
};

JobsList.defaultProps = {
  jobsList: [],
};
