import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./PageTitleSlot.css";

export default function PageTitleSlot({ className, children }) {
  return (
    <div className={classNames("page-title-slot", className)}>
      <div className="page-title-slot__inner">{children}</div>
    </div>
  );
}

PageTitleSlot.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
