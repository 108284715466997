import React from "react";
import classNames from "classnames";
import { dotDisplayName } from "libs";

import CoverImage from "./components/CoverImage/CoverImage";
import Content from "./components/Content/Content";
import Title from "./components/Title/Title";
import Text from "./components/Text/Text";
import ActionButton from "./components/ActionButton/ActionButton";

import "./JobItemCard.css";

export default function JobItemCard({ className, children }) {
  return (
    <div className={classNames("job-item-card", className)}>
      <div>{children}</div>
    </div>
  );
}

JobItemCard.CoverImage = dotDisplayName("JobItemCard", CoverImage);
JobItemCard.Content = dotDisplayName("JobItemCard", Content);
JobItemCard.Title = dotDisplayName("JobItemCard", Title);
JobItemCard.Text = dotDisplayName("JobItemCard", Text);
JobItemCard.ActionButton = dotDisplayName("JobItemCard", ActionButton);
