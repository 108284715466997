import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./ContentImageBlock.css";

export default function ContentImageBlock({ className, src }) {
  return (
    <div className={classNames("content-image-block", className)}>
      <img className="content-image-block__image" src={src} alt={src} />
    </div>
  );
}

ContentImageBlock.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
};
