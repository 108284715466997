import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import { ContentBlock, PageTitleSlot } from "ui-kit";

import "./FaqPage.css";

export default function FaqPage({ isMinimalHeader }) {
  return (
    <div className="faq-page route-page">
      <PageTitleSlot className={classnames({ "faq-page--minimal-header": isMinimalHeader })}>
        <h3 className="route-page__title">
          <FormattedMessage id="faq_page.title" />
        </h3>
      </PageTitleSlot>
      <ContentBlock>
        {[...Array(13)].map((x, index) => (
          <details className="faq-page__card" key={index}>
            <summary className="faq-page__card-title">
              <FormattedMessage id={`faq_page.question_${index}`} />
            </summary>
            <p className="faq-page__card-desc">
              <FormattedMessage id={`faq_page.answer_${index}`} />
            </p>
          </details>
        ))}
      </ContentBlock>
    </div>
  );
}

FaqPage.proptype = {
  isMinimalHeader: PropTypes.bool,
};

FaqPage.defaultProps = {
  isMinimalHeader: false,
};
