import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { BiMailSend, BiPhone, BiMap } from "react-icons/bi";
import { HiOutlineNewspaper } from "react-icons/hi";

import "./Contact.css";

export default function Contact({ layoutType }) {
  return (
    <div
      className={classNames("contact", {
        "contact--column": layoutType === "column",
      })}
    >
      <ul className="contact__list">
        <li className="contact__list-item">
          <BiPhone className="contact__list-icon" />
          <a href="tel:+37067727200">+373 67727200</a>
        </li>
        <li className="contact__list-item">
          <BiPhone className="contact__list-icon" />
          <a href="tel:+373069162204">+373 69162204</a>
        </li>
        <li className="contact__list-item">
          <BiMailSend className="contact__list-icon" />
          <a href="mailto:jobsagencym@gmail.com">jobsagencym@gmail.com</a>
        </li>
        <li className="contact__list-item">
          <HiOutlineNewspaper className="contact__list-icon" />
          <a href="tel: +37322844550">+373 22 844 550</a>
        </li>
        <li className="contact__list-item">
          <BiMap className="contact__list-icon" />
          <span>mun. Chișinău, str. Armenească 44/2, of.6</span>
        </li>
      </ul>
    </div>
  );
}

Contact.proptype = {
  layoutType: PropTypes.oneOf(["column", "row"]),
};

Contact.defaultProps = {
  layoutType: "row",
};
