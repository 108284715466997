import React from "react";
import PropTypes from "prop-types";

import "./Text.css";

export default function Text({ children }) {
  return <p className="job-item-card__text">{children}</p>;
}

Text.propTypes = {
  children: PropTypes.node,
};
