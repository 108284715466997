const jobs = {
  order_collector: {
    id: "order_collector",
  },
  empty_container_sorter: {
    id: "empty_container_sorter",
  },
  truck_driver: {
    id: "truck_driver",
  },
  project_manager: {
    id: "project_manager",
  },
  warehouse_order_collector: {
    id: "warehouse_order_collector",
  },
  loader_driver: {
    id: "loader_driver",
  },
  high_rise_loader_driver: {
    id: "high_rise_loader_driver",
  },
  warehouse_workers: {
    id: "warehouse_workers",
  },
  project_coordinators: {
    id: "project_coordinators",
  },
  furniture_movers: {
    id: "furniture_movers",
  },
  foremen: {
    id: "foremen",
  },
  medical_assistant: {
    id: "medical_assistant",
  },
};

export { jobs };
