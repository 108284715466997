import React from "react";

import "./CoverImage.css";

export default function CoverImage({ src, alt }) {
  return (
    <div className="job-item-card__cover">
      <img className="job-item-card__cover-image" src={src} alt={alt} />
    </div>
  );
}
