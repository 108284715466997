import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";

import { I18nProvider } from "i18n";
import { Header, Footer } from "components";
import ScrollRestoration from "utils/ScrollRestoration";

import { HomePage, AboutPage, JobsListPage, FaqPage, ContactsPage, FormSuccess } from "./pages";
import {
  HOME_ROUTE,
  ABOUT_ROUTE,
  JOBSLIST_ROUTE,
  FAQ_ROUTE,
  CONTACTS_ROUTE,
  FORM_SUCCESS_ROUTE,
} from "constants/Routing";

function App() {
  return (
    <Router>
      <I18nProvider>
        <ScrollRestoration />
        <Header />
        <main className="content">
          <Switch>
            <Route exact path={HOME_ROUTE}>
              <HomePage />
            </Route>
            <Route exact path={ABOUT_ROUTE}>
              <AboutPage />
            </Route>
            <Route path={JOBSLIST_ROUTE}>
              <JobsListPage />
            </Route>
            <Route exact path={FAQ_ROUTE}>
              <FaqPage />
            </Route>
            <Route exact path={CONTACTS_ROUTE}>
              <ContactsPage />
            </Route>
            <Route exact path={FORM_SUCCESS_ROUTE}>
              <FormSuccess />
            </Route>
          </Switch>
        </main>
        <Footer />
      </I18nProvider>
    </Router>
  );
}

export default App;
