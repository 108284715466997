import React from "react";
import { FormattedMessage } from "react-intl";

import { ContentBlock, PageTitleSlot } from "ui-kit";
import { Contact } from "components";
import "./AboutPage.css";

export default function AboutPage() {
  return (
    <div className="about-page route-page">
      <PageTitleSlot>
        <h3 className="route-page__title">
          <FormattedMessage id="about_page.title" />
        </h3>
      </PageTitleSlot>
      <ContentBlock>
        <img className="about-page__logo" src="/assets/images/logo.png" alt="" />
        <img className="about-page__logo--fixed" src="/assets/images/logo.png" alt="" />
      </ContentBlock>
      <ContentBlock>
        <article>
          <h4>
            <FormattedMessage id="about_page.subtitle" />
          </h4>
          <p>
            <FormattedMessage id="about_page.desc" values={{ linebreak: <br /> }} />
          </p>
        </article>
      </ContentBlock>
      <ContentBlock>
        <article>
          <h4>
            <FormattedMessage id="about_page.subtitle_1" />
          </h4>
          <p>
            <FormattedMessage id="about_page.desc_1" values={{ linebreak: <br /> }} />
          </p>
        </article>
      </ContentBlock>
      <ContentBlock>
        <article>
          <h4>
            <FormattedMessage id="about_page.benefits_title" />
          </h4>
          <p>
            <FormattedMessage id="about_page.benefits_desc" values={{ linebreak: <br /> }} />
          </p>
        </article>
      </ContentBlock>
      <ContentBlock>
        <article>
          <h4>
            <FormattedMessage id="about_page.benefits_title_1" />
          </h4>
          <p>
            <FormattedMessage id="about_page.benefits_desc_1" values={{ linebreak: <br /> }} />
          </p>
        </article>
      </ContentBlock>

      <ContentBlock>
        <img className="doc-photo" src="/assets/images/licenta.png" alt="" />
      </ContentBlock>
      <ContentBlock>
        <Contact />
      </ContentBlock>
    </div>
  );
}
