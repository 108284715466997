import React, { useContext } from "react";
import classnames from "classnames";
import { Context, LOCALES } from "i18n";

import "./LanguageSelect.css";

export default function LanguageSelect() {
  const context = useContext(Context);

  return (
    <div className="language-select">
      <label
        className={classnames("language-select__item", {
          "language-select__item--active": context.locale === LOCALES.RUSSIAN,
        })}
        htmlFor="language_ru"
      >
        ru
        <input
          type="radio"
          name="language"
          id="language_ru"
          value={LOCALES.RUSSIAN}
          onChange={context.selectLang}
        />
      </label>
      <label
        className={classnames("language-select__item", {
          "language-select__item--active": context.locale === LOCALES.ROMANIAN,
        })}
        htmlFor="language_ro"
      >
        ro
        <input
          type="radio"
          name="language"
          id="language_ro"
          value={LOCALES.ROMANIAN}
          onChange={context.selectLang}
        />
      </label>
    </div>
  );
}
